import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/layout';

const Row = styled('section')`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const Hero = styled(Row)`
  min-height: calc(100vh - 12rem);
`;

const Column = styled('div')`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
`;

const AboutImage = styled(Image)`
  border: 7px solid #222;
  overflow: visible !important;
  max-width: 400px;
  width: 100%;

  img {
    margin-top: 0;
    transform: translate(20px, 25px);
  }
`;

const Copy = styled('div')`
  align-self: flex-start;

  h1 {
    font-size: 4rem;

    + * {
      color: #333;
      font-size: 1rem;
      opacity: 0.5;
    }
  }

  @media (min-width: 992px) {
    h1 {
      font-size: 6rem;
    }
  }

  @media (min-width: 1200px) {
    h1 {
      font-size: 8rem;
    }
  }
`;

export default () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "NorbertKalacska-about.jpeg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout fullWidth={true}>
      <Hero>
        <Column>
          <AboutImage
            fluid={image.sharp.fluid}
            alt="Norbert Kalácska - Black Turtle Neck"
            fadeIn
          />
        </Column>
        <Column
          css={css`
            padding-left: 0;
            padding-right: 0;

            @media (min-width: 768px) {
              padding-left: 2rem;
              padding-right: 2rem;
            }
          `}
        >
          <Copy>
            <h1>From Legos To Software</h1>
            <p>A story of why and how I've become a developer.</p>
          </Copy>
        </Column>
      </Hero>
    </Layout>
  );
};
